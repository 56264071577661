import i18next from 'i18next';
import * as PIXI from 'pixi.js';
import { BaseModal } from './BaseModal';

export class CashoutInfoModal extends BaseModal {
    /**
     * @param rootContainer {SceneManager}
     * @param model {Model}
     * @param resizeManager {ResizeManager}
     * @param soundManager {SoundManager}
     */
    constructor(rootContainer, model, resizeManager, soundManager) {
        super(rootContainer, model, resizeManager, soundManager);

        this.soundManager = soundManager;

        this.addInteractForBg();
    }

    createModal() {
        const container = new PIXI.Container();

        const bg = container.addChild(PIXI.Sprite.from('cashoutInfoBg'));
        bg.pivot.set(bg.width / 2, bg.height / 2);
        bg.position.set(540, 1260);

        const text = container.addChild(new PIXI.Text(i18next.t('tooltips.want_to_cash_out'), {
            fontFamily: 'Mybahnschrift',
            fontSize: '52px',
            fontWeight: 'lighter',
            lineHeight: 80,
            letterSpacing: 5,
            fill: "#FFFFFF",
            align: "center",
        }));
        text.anchor.set(0.5);
        text.position.set(520, 1200);

        const info = container.addChild(PIXI.Sprite.from('infoBtnNormal'));
        info.pivot.set(info.width / 2, info.height / 2);
        info.position.set(404, 1600);

        const icon = container.addChild(PIXI.Sprite.from('cashoutButtonNormal'));
        icon.pivot.set(icon.width / 2, icon.height / 2);
        icon.position.set(info.x + info.width / 2 + icon.width / 2 + 5, 1600);

        const textButton = container.addChild(new PIXI.Text(i18next.t('main_scene.cashout_button'), {
            fontFamily: 'Mybahnschrift',
            fontSize: '32px',
            fontWeight: 'lighter',
            fill: "#FFFFFF",
            align: "center",
        }));
        textButton.position.set(icon.x, icon.y - 28);
        textButton.anchor.set(0.5);
        textButton.zIndex = 2;

        this.textBetAmount = container.addChild(new PIXI.Text('', {
            fontFamily: 'Mybahnschrift',
            fontSize: '40px',
            fill: "#FFD809",
            align: "center",
            fontWeight: "bold",
        }));
        this.textBetAmount.position.set(icon.x + 5, icon.y + 16);
        this.textBetAmount.text = `${this.model.gdh.formatCurrency(this.model.gdh.cashout, this.model.gdh.currency)}`
        this.textBetAmount.anchor.set(0.5);
        this.textBetAmount.zIndex = 2;

        this.addAnimationItems([bg, text, icon, info]);

        return container;
    }

    show() {
        super.show();
        const value = this.model.gdh.cashout;
        if (!isNaN(value) && value >= 0) {
            this.textBetAmount.text = `${this.model.gdh.formatCurrency(this.model.gdh.cashout, this.model.gdh.currency)}`;
        }
    }


    addInteractForBg() {
        this.blackBg.on('pointerup', async (e) => {
            e.stopPropagation();
            await this.hide();
            this.model.cashoutInfoModal$.next(false);
        });
        this.blackBg.on('pointerdown', () => {
            this.soundManager.playSound('click');
        })
    }
}
