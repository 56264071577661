import Constants from "../constans/Constants.js";

export const getRandomValueBetween = (min = 1, max = 6) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

export const round = (value, multiplier) => {
  return Math.round(value * multiplier) / multiplier;
};

export const getRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const formatCurrency = (amount, currencyCode) => {
  currencyCode = currencyCode || this.currency;

  try {
    amount = (Number(amount) || 0).toFixed(2);
  } catch (e) {
    amount = (0).toFixed(2);
  }

  switch (currencyCode) {
    case "EUR":
      return `€${numberWithCommas(amount)}`;
    case "INR":
      return `₹${numberWithCommas(amount)}`;
    case "TMT":
      return `${numberWithCommas(amount)}m`;
    case "TRY":
      return `₺${numberWithCommas(amount)}`;
    case "MXN":
      return `$${numberWithCommas(amount)}`;
    case "THB":
      return `฿${numberWithCommas(amount)}`;
    case "BTN":
      return `Nu.${numberWithCommas(amount)}`;
    case "KZT":
      return `${numberWithCommas(amount)}₸`;
    case "CLP":
    case "XXX":
      return `$${numberWithCommas(amount)}`;
    case "ILS":
      return `₪${numberWithCommas(amount)}`;
    default:
      return `${numberWithCommas(amount)} ${currencyCode}`;
  }
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const tokenMap = (position) => {
  if (position === 51) {
    return -1;
  }

  if (position > 51) {
    return position;
  }

  return position + 1;
};

export const tokenMapForMove = (position) => {
  if (position === 51) {
    return -1;
  }

  return position;
};

export const tokenMoveType = (fromPosition, toPosition, index = null) => {
  if (
    fromPosition === Constants.MOVES_POSITIONS.HOME &&
    toPosition === Constants.MOVES_POSITIONS.START
  ) {
    return Constants.ROUND_MOVE_TYPES.RELEASE; // RELEASE
  }

  if (
    fromPosition > Constants.MOVES_POSITIONS.START &&
    toPosition === Constants.MOVES_POSITIONS.HOME
  ) {
    return Constants.ROUND_MOVE_TYPES.KILL;
  }

  if (toPosition === Constants.MOVES_POSITIONS.FINISH) {
    return Constants.ROUND_MOVE_TYPES.HOME;
  }

  if (fromPosition === 0 && toPosition === 0 && index === 0) {
    return Constants.ROUND_MOVE_TYPES.ROLL;
  }

  return Constants.ROUND_MOVE_TYPES.OTHER;
};

/**
 * @param players {Player[]}
 * @return {{tokens: number[], color: string, idx: number, finishBlocked: boolean}[]}
 */
export const mapPlayersForRollRequest = (players) => {
  const mappedPlayers = [];
  players.forEach((player) => {
    const obj = {
      tokens: player.tokens.map((token) => token.position),
      color: player.teamColor,
      idx: player.index,
      finishBlocked: player.finishBlocked,
    };
    mappedPlayers.push(obj);
  });
  return mappedPlayers;
};

export const coinsToRegularBalance = (coins) => {
  const _coins = Number(coins);
  if (Number.isNaN(_coins)) {
    return 0;
  }

  return _coins / Constants.DETERMINANT_BALANCE;
};

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const enableCheatMode = () => {
  return process.env.CHEATS_ENABLED && process.env.CHEATS_ENABLED === "true";
};

export const isEmptyArray = (arr) => {
  return !Array.isArray(arr) || arr.length === 0;
};

export function getRandomElements(arr, count, exclude) {
  const filteredArray = arr.filter((item) => item !== exclude);

  if (filteredArray.length === 0) {
    throw new Error("getRandomElements array.length = 0");
  }

  const shuffledArray = filteredArray.sort(() => Math.random() - 0.5);
  return shuffledArray.slice(0, count);
}