import * as PIXI from "pixi.js";
import i18next from "i18next";
import {gsap} from "gsap";
import {distinctUntilChanged} from "rxjs";
import { EnumGameModes } from "../helpers/constants/GameModes";
import { SpineHelper } from "../helpers/SpineHelper";
import {Sprite} from "pixi.js";
import {EnumSpriteButtonState} from "./SpriteButton";
import {skip} from "rxjs/operators";
import {ReactiveButton} from "./ReactiveButton";

export class ReactiveCashoutButton extends ReactiveButton {
    constructor(model) {
        super(model);
        this.model = model;

        this.timerCashoutButton$ = model.timerCashoutButton$;

        this.gameMode$
            .pipe(distinctUntilChanged())
            .subscribe(value => {
                if (this.animatedBg && this.animatedBg.slots) this.animatedBg.destroy();

                if (value === EnumGameModes.SINGLE) {
                    this.animatedBg = this.addChild(new SpineHelper('cashoutBtn'));
                    this.animatedBg.skeleton.setSkinByName('default');
                    this.animatedBg.position.set(175, 87);
                    this.animatedBg.zIndex = 1;
                    this.animatedBgWidth = 265;
                    this.animatedBgHeight = 115;

                    this.staticBg = this.addChild(new Sprite(
                        PIXI.Texture.from('cashoutButtonHover')
                    ));
                    this.staticBg.position.set(this.animatedBg.x, this.animatedBg.y);
                    this.staticBg.anchor.set(0.5);
                    this.staticBg.zIndex = 1;
                    this.staticBg.visible = false;
                }
            });

        this.timerCashoutButton$
            .subscribe(_ => {
                if (!this.animatedBg) return;

                this.animatedBg.setAnimation('animation');
                const duration = this.animatedBg.animations['animation'];

                this.animatedBg.tween = gsap.fromTo(this.animatedBg,
                    {
                        time: 0,
                    },
                    {
                        time: duration,
                        duration: duration,
                        repeat: -1,
                        repeatDelay: 3,
                    });
                });
        this.text = this.addChild(new PIXI.Text(i18next.t('main_scene.cashout_button'), {
            fontFamily: 'Mybahnschrift',
            fontSize: '32px',
            fontWeight: 'lighter',
            fill: "#FFFFFF",
            align: "center",
        }));
        this.text.position.set(this.animatedBgWidth / 2 + 45, 60);
        this.text.anchor.set(0.5);
        this.text.zIndex = 3;

        this.disabledButton = this.addChild(new PIXI.Sprite(PIXI.Texture.from("cashoutButtonDisabled")));
        this.disabledButton.position.set(42, 30);
        this.disabledButton.zIndex = 2;
        this.disabledButton.visible = false;

        this.textBetAmount = this.addChild(new PIXI.Text('', {
            fontFamily: 'Mybahnschrift',
            fontSize: '40px',
            fill: "#FFD809",
            align: "center",
            fontWeight: "bold",
        }));
        this.textBetAmount.position.set(this.animatedBgWidth / 2 + 50, 104);
        this.textBetAmount.text = `${this.model.gdh.formatCurrency(this.model.cashout$, this.model.gdh.currency)}`
        this.textBetAmount.anchor.set(0.5);
        this.textBetAmount.zIndex = 2;

        this.model.cashout$.subscribe((data) => {
            if (this.model.gdh.urlParams.get('debug')) {
                console.log("cashout value", data);
            }
            if (data && !isNaN(data.value) && data.value >= 0 && !this.model.cashoutModal$.value) {
                this.textBetAmount.text = `${this.model.gdh.formatCurrency(data.value.toString(), this.model.gdh.currency)}`;
            }
            this.cashoutValue = data.value;
            this.disabled = this.cashoutValue > 0 ? !this.isEnabledCashout : true;
        });

        this.model.enableCashoutButton$
          .pipe(skip(1))
          .subscribe(value => {
              this.isEnabledCashout = value.enabled;
              this.disabled = this.cashoutValue > 0 ? !this.isEnabledCashout : true;
          });

        this.model.cashoutModal$
          .pipe(distinctUntilChanged())
          .subscribe((isActive) => {
              const data = this.model.cashout$.value.value;
              if (!isActive && data && !isNaN(data.value) && data.value >= 0) {
                  this.model.gdh.cashout = data;
                  this.textBetAmount.text = `${this.model.gdh.formatCurrency(this.model.gdh.cashout, this.model.gdh.currency)}`;
              }
          });
    }

    get disabled() {
        return this._disabled;
    }

    set disabled(value) {
        this._disabled = value;
        this.eventMode = value ? 'none' : 'static';
        this._state = EnumSpriteButtonState.DEFAULT;
        this.disabledButton.visible = value;

        this.text.style.fill = value ? "#72749B" : "#FFFFFF";
        this.textBetAmount.style.fill = value ? "#5F6080" : "#FFD809";

        if (this.gameMode$.value === EnumGameModes.MULTI) {
            if (this.animatedBg.tween) {
                this.animatedBg.tween.pause(1);
                this.animatedBg.tween.kill();
                this.animatedBg.tween = null;
            }
        }
    }

    press() {
        gsap.getTweensOf(this).forEach(tween => {
            tween.pause(1);
            tween.kill();
        });

        gsap.to(this, {
            width: this.animatedBgWidth * 0.95,
            height: this.animatedBgHeight * 0.95,
            duration: 0.1,
            ease: "power2.out"
        });
    }

    unpress() {
        gsap.getTweensOf(this).forEach(tween => {
            tween.pause(1);
            tween.kill();
        });

        gsap.to(this, {
            width: this.animatedBgWidth,
            height: this.animatedBgHeight,
            duration: 0.1,
            ease: "power2.out"
        });
    }

    pointerOver() {
        this.staticBg.visible = true;
        this.animatedBg.visible = false;
    }

    pointerOut() {
        this.staticBg.visible = false;
        this.animatedBg.visible = true;
    }
}
